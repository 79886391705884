import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "cell-item"
};
const _hoisted_2 = {
  class: "cell-item"
};
const _hoisted_3 = {
  class: "cell-item"
};
const _hoisted_4 = {
  class: "cell-item"
};
const _hoisted_5 = {
  class: "cell-item"
};
const _hoisted_6 = {
  class: "cell-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AddLocation = _resolveComponent("AddLocation");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_Refrigerator = _resolveComponent("Refrigerator");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_Odometer = _resolveComponent("Odometer");
  const _component_Key = _resolveComponent("Key");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("h1", null, _toDisplayString(_ctx.$t("signedData.title")), 1), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(_ctx.$t("signedData.text")) + " ", 1), _createVNode(_component_el_descriptions, {
    class: "loadingDataTable",
    title: _ctx.$t('signedData.loadingdata'),
    column: 1,
    size: "large",
    border: true
  }, {
    default: _withCtx(() => [_createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_AddLocation)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.chargingPoint")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.chargingdata.chargingpoint?.name), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Refrigerator)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.chargingPointSN")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.chargingdata.chargingpoint?.serialNumber), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Calendar)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.startedAt")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.formatDate(_ctx.chargingdata.startedAt)), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Calendar)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.endedAt")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.formatDate(_ctx.chargingdata.endedAt)), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Odometer)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.totalEnergy")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.formatEnergy(_ctx.chargingdata.energy?.totalEnergy)), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Key)]),
        _: 1
      }), _createTextVNode(" " + _toDisplayString(_ctx.$t("signedData.publicKey")), 1)])]),
      default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(_ctx.publicKey), 1)]),
      _: 1
    })]),
    _: 1
  }, 8, ["title"]), _createVNode(_component_el_button, {
    type: "primary",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.downloadSignedData())
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("signedData.downloadSignedDataFile")), 1)]),
    _: 1
  })])], 64);
}