import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import config from '@/config';
import axios from 'axios';
import { defineComponent } from 'vue';
import { ChargingProcessStatus } from '@/model/chargingProcessStatus';
export default defineComponent({
  name: 'HomeForm',
  data() {
    return {
      chargeActual: [],
      chargeHistory: []
    };
  },
  async mounted() {
    this.load();
  },
  methods: {
    async load() {
      const actualStatus = [ChargingProcessStatus.RUNNING, ChargingProcessStatus.INIZIALIZING, ChargingProcessStatus.SUSPENDED
      //  ChargingProcessStatus.CLOSED -> Removed for Messe 24/10/23
      ];
      const g = await axios.get(config().ChargingHistoryUrl, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      this.chargeHistory = g.data;
      this.chargeActual = g.data.filter(f => actualStatus.includes(f.status));
      //console.log("this.chargeHistory", this.chargeHistory);
    }
  }
});