import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "login"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_input = _resolveComponent("el-input");
  const _component_DTPicker = _resolveComponent("DTPicker");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_card, null, {
    header: _withCtx(() => [_createElementVNode("h2", {
      innerHTML: _ctx.loginTitle
    }, null, 8, _hoisted_2), _createElementVNode("div", {
      class: "desc",
      innerHTML: _ctx.loginDescription
    }, null, 8, _hoisted_3)]),
    default: _withCtx(() => [_ctx.loginerror ? (_openBlock(), _createBlock(_component_el_alert, {
      key: 0,
      title: "Login",
      type: "error",
      center: "",
      effect: "dark",
      class: "loginerror"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("login.LoginIncorrect")), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_el_form, {
      class: "login-form",
      "label-position": "top",
      ref: "form",
      onSubmit: _withModifiers(_ctx.login, ["prevent"])
    }, {
      default: _withCtx(() => [_ctx.loginVariant == _ctx.LoginVariant.ROOMDESCRIPTION_BIRTHDAY ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        prop: "room",
        label: _ctx.$t('login.roomNumber')
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: _ctx.room,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.room = $event),
          placeholder: "Room"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.value,
              label: item.value,
              value: item.value
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _ctx.loginVariant == _ctx.LoginVariant.ROOM_BIRTHDAY ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 1,
        prop: "room",
        label: _ctx.$t('login.roomNumber')
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.room,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.room = $event),
          placeholder: "Number",
          "prefix-icon": "Key"
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
        prop: "birthday",
        label: _ctx.$t('login.birthday')
      }, {
        default: _withCtx(() => [_createVNode(_component_DTPicker, {
          format: "DD/MM/YYYY",
          placeholder: _ctx.$t('login.birthday'),
          modelValue: _ctx.birthday,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.birthday = $event),
          style: {
            "width": "100%"
          }
        }, null, 8, ["placeholder", "modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          loading: _ctx.loading,
          disabled: _ctx.v$.$invalid,
          class: "login-button",
          type: "primary",
          "native-type": "submit",
          block: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login.login')), 1)]),
          _: 1
        }, 8, ["loading", "disabled"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["onSubmit"])]),
    _: 1
  })]);
}