import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "../components/Login.vue";
import SignedData from '@/views/SignedData.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signedData/:chargeboxid/:id/:pwd?',
    name: 'signedData',
    component: SignedData
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: '/start',
    name: 'start',
    // route level code-splitting
    // this generates a separate chunk (start.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start" */ '../views/StartChargingView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    const exp = sessionStorage.getItem("userExpire");
    if(authRequired && (!exp || new Date(Number(exp)*1000) < new Date()))
      next('/login');
    else
      next();
  }
});

export default router
