export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our charging service"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have the energy you need.<br/><br/> Login with your credentials and charge your vehicle."])},
    "roomNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Number"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your birthday"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "LoginIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not log you in. Please check your credentials."])}
  },
  "signedData": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforming Charging Processes"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charging processes are billed in accordance with regulations and can be viewed via the S.A.F.E. portal."])},
    "loadingdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Data"])},
    "chargingPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charging Point"])},
    "chargingPointSN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
    "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Energy"])},
    "downloadSignedDataFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download signed data as XML"])},
    "downloadPublicKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download public key"])},
    "publicKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])}
  },
  "charging": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Charging"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charging Process started"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start new Charging Process"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop the actual Charging Process"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "currentEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Energy"])},
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Energy"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Charge"])},
    "noCharging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No actual Charging process"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "viewSignedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View signed data"])}
  },
  "chargingHistory": {
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Energy"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of Charge"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "charging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charging"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No previous chargings"])},
    "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initializing"])},
    "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended EVSE"])},
    "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Invoice"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preinvoicing"])},
    "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Invoicing"])},
    "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoiced"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])}
  },
  "duration": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
  },
  "startcharging": {
    "titlePoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your charging point"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Charging"])},
    "openend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start immediatly"])},
    "openendDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge as much as possibile and stop the process when you unplug the cable."])},
    "fixedAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Charge ", _interpolate(_named("amt")), " kwh"])},
    "fixedAmountDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select how much to charge and let the process stop when this amount has been reached"])},
    "plugType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plug Type"])},
    "powerClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Class"])},
    "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
    "titleChargingRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process starting"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "CHARGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied"])},
    "FINISHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finishing"])},
    "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "PREPARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "Faulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "FAULTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "errorActivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in activation the charge"])},
    "chargingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The charging process started"])}
  }
}