import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "charging"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.chargingProcesses.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_empty, {
    description: _ctx.$t('chargingHistory.noHistory')
  }, null, 8, ["description"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("h3", null, _toDisplayString(_ctx.$t("charging.history")), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chargingProcesses, chargingProcess => {
    return _openBlock(), _createBlock(_component_el_card, {
      key: chargingProcess.id
    }, {
      header: _withCtx(() => [_createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.translateStatus(chargingProcess.status)), 1)]),
      default: _withCtx(() => [_createVNode(_component_el_row, {
        gutter: 10
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4,
          justify: "space-evenly",
          class: "svgdiv"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 576 512",
            class: "chargingIcon"
          }, [_createElementVNode("path", {
            d: "M336 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h320c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm208-320V80c0-8.84-7.16-16-16-16s-16 7.16-16 16v48h-32V80c0-8.84-7.16-16-16-16s-16 7.16-16 16v48h-16c-8.84 0-16 7.16-16 16v32c0 35.76 23.62 65.69 56 75.93v118.49c0 13.95-9.5 26.92-23.26 29.19C431.22 402.5 416 388.99 416 372v-28c0-48.6-39.4-88-88-88h-8V64c0-35.35-28.65-64-64-64H96C60.65 0 32 28.65 32 64v352h288V304h8c22.09 0 40 17.91 40 40v24.61c0 39.67 28.92 75.16 68.41 79.01C481.71 452.05 520 416.41 520 372V251.93c32.38-10.24 56-40.17 56-75.93v-32c0-8.84-7.16-16-16-16h-16zm-283.91 47.76l-93.7 139c-2.2 3.33-6.21 5.24-10.39 5.24-7.67 0-13.47-6.28-11.67-12.92L167.35 224H108c-7.25 0-12.85-5.59-11.89-11.89l16-107C112.9 99.9 117.98 96 124 96h68c7.88 0 13.62 6.54 11.6 13.21L192 160h57.7c9.24 0 15.01 8.78 10.39 15.76z"
          })], -1)])),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 10,
          xs: 20
        }, {
          default: _withCtx(() => [_createVNode(_component_el_descriptions, {
            column: 1
          }, {
            default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
              label: _ctx.$t('chargingHistory.start')
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.formatDateTime(chargingProcess.startedAt)), 1)]),
              _: 2
            }, 1032, ["label"]), _createVNode(_component_el_descriptions_item, {
              label: _ctx.$t('chargingHistory.totalEnergy')
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString((chargingProcess.energy?.totalEnergy / 1000).toFixed(2)) + " KWh", 1)]),
              _: 2
            }, 1032, ["label"]), _ctx.getDuration(chargingProcess) != 0 ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
              key: 0,
              label: _ctx.$t('chargingHistory.duration')
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.getDuration(chargingProcess)), 1)]),
              _: 2
            }, 1032, ["label"])) : _createCommentVNode("", true)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024), _createVNode(_component_el_col, {
          span: 10,
          xs: 24
        }, {
          default: _withCtx(() => [_ctx.statusIsRunning(chargingProcess.status) ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "warning",
            icon: "Minus",
            size: "large",
            onClick: $event => _ctx.stopCharge(chargingProcess)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("charging.stop")), 1)]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), !_ctx.statusIsRunning(chargingProcess.status) && _ctx.IsEichkonform ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            type: "primary",
            icon: "Check",
            size: "large",
            tag: "router-link",
            to: {
              name: 'signedData',
              params: {
                chargeboxid: chargingProcess.chargingpoint.id,
                id: chargingProcess.id
              }
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("charging.viewSignedData")), 1)]),
            _: 2
          }, 1032, ["to"])) : _createCommentVNode("", true)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024);
  }), 128))]))]);
}