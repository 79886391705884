import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { defineComponent } from 'vue';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import axios, { isAxiosError } from 'axios';
import config from '../config';
import { LoginVariant } from '@/model/loginVariants';
//import DTPicker from './DTPicker.vue';
import moment from 'moment';
import router from '@/router';
import { jwtDecode } from 'jwt-decode';
export default defineComponent({
  name: 'LoginForm',
  inject: ["TENANT"],
  mounted() {
    const tenant = this.TENANT;
    if (tenant) {
      if (tenant.loginTitle) this.loginTitle = tenant.loginTitle;
      if (tenant.loginDescription) this.loginDescription = tenant.loginDescription;
      this.tenantId = tenant.id;
      this.getLoginVariant();
    }
    const r = localStorage.getItem("lastRoom");
    if (r) this.room = r;
    const b = localStorage.getItem("lastBirthday");
    this.birthday = b ? moment(b).toDate() : new Date();
  },
  data() {
    return {
      LoginVariant: LoginVariant,
      v$: useValidate(),
      room: "",
      birthday: null,
      loading: false,
      tenantId: "4d011a5d-7242-46cf-a2cc-832285ddb5a1",
      loginTitle: this.$t('login.title'),
      loginDescription: this.$t('login.description'),
      loginVariant: LoginVariant.ROOM_BIRTHDAY,
      rooms: [],
      loginerror: false
    };
  },
  validations() {
    return {
      room: {
        required
      },
      birthday: {
        required
      }
    };
  },
  methods: {
    async makeLogin(room, birthday) {
      // Fix the timezone. The server applys the +1 Timezone and the 00:00:00 time is than a day before.
      birthday.setMinutes(birthday.getTimezoneOffset() * -1);
      const g = await axios.post(config().LoginUrl, {
        room: room,
        birthday: moment.utc(birthday).toISOString(true),
        TenantId: this.tenantId
      });
      return g.data;
    },
    async getLoginVariant() {
      const g = await axios.get(config().LoginVariant.replace("{tenantId}", this.tenantId));
      if (g.data.length > 0) this.loginVariant = g.data[0];else this.loginVariant = LoginVariant.ROOM_BIRTHDAY;
      if (this.loginVariant == LoginVariant.ROOMDESCRIPTION_BIRTHDAY) this.getRooms();
    },
    async getRooms() {
      const g = await axios.get(config().Rooms.replace("{tenantId}", this.tenantId));
      if (g.data) this.rooms = g.data.sort((a, b) => a.value > b.value ? 1 : -1);else this.rooms = [];
    },
    async login() {
      try {
        this.loginerror = false;
        this.v$.$validate(); // checks all inputs
        if (this.v$.$error) {
          this.v$.$errors.forEach(element => {
            ElMessage({
              message: element.$message,
              type: 'error'
            });
          });
        }
        this.loading = true;
        localStorage.setItem("lastRoom", this.room);
        localStorage.setItem("lastBirthday", (this.birthday || new Date()).toISOString());
        const user = await this.makeLogin(this.room, this.birthday || new Date());
        sessionStorage.setItem("user", JSON.stringify(user));
        const decoded = jwtDecode(user);
        sessionStorage.setItem("userExpire", decoded.exp.toString());
        this.loading = false;
        ElMessage({
          message: 'Login successfull.',
          type: 'success'
        });
        router.push({
          name: 'home'
        });
      } catch (e) {
        this.loading = false;
        if (isAxiosError(e)) {
          console.log(this.loginerror);
          if (e.status == 401) {
            this.loginerror = true;
            return;
          }
        }
        ElMessage({
          message: 'Login invalid: ' + e,
          type: 'error'
        });
      }
    }
  }
});