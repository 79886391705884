import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import config from '@/config';
import axios from 'axios';
import { defineComponent } from 'vue';
import moment from 'moment';
import { ElMessageBox } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'

export default defineComponent({
  name: 'SignedData',
  inject: ["TENANT"],
  data() {
    return {
      chargeActual: [],
      chargeHistory: [],
      chargingdata: {},
      publicKey: ""
    };
  },
  async mounted() {
    this.load();
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm") : "-";
    },
    formatEnergy(watt) {
      return watt ? (watt / 1000).toFixed(2) + " kw" : "-";
    },
    async load() {
      const chargingId = this.$route.params.id;
      const pwd = this.$route.params.pwd;
      if (!pwd && !sessionStorage.getItem("user")) {
        ElMessageBox.alert("Please login to download the signed data file", 'Login or Pwd required');
        return;
      }
      const g = pwd ? await axios.get(config().ChargingDetailUrl.replace("${id}", chargingId) + "/" + pwd) : await axios.get(config().ChargingDetailUrl.replace("${id}", chargingId), {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      this.chargingdata = g.data;
      try {
        const chargeboxId = this.$route.params.chargeboxid;
        const ten = this.TENANT.id;
        const p = await axios.get(config().GetPublicKey.replace("${chargingPointId}", chargeboxId).replace("${tenantId}", ten), {
          headers: {
            Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
          }
        });
        this.publicKey = p.data;
      } catch (e) {
        this.publicKey = "Error loading public key";
      }
    },
    getSignedDataUrl() {
      const chargingId = this.$route.params.id;
      return config().DownloadSignedData.replace("${chargingId}", chargingId);
    },
    async downloadSignedData() {
      try {
        const chargingId = this.$route.params.id;
        const pwd = this.$route.params.pwd;
        if (!pwd && !sessionStorage.getItem("user")) {
          ElMessageBox.alert("Please login to download the signed data file", 'Login or Pwd required');
          return;
        }
        const ten = this.TENANT.id;
        const response = pwd ? await axios.get(config().DownloadSignedData.replace("${chargingId}", chargingId).replace("${tenantId}", ten) + "/" + pwd, {
          responseType: 'blob'
        }) : await axios.get(config().DownloadSignedData.replace("${chargingId}", chargingId), {
          headers: {
            Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
          },
          responseType: 'blob'
        });
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'signedData_' + chargingId + '.xml');
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        ElMessageBox.alert(e.message, 'Sorry we have problems generating the signed data file');
      }
    }
  }
});