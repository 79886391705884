import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "newCharging"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_router_link = _resolveComponent("router-link");
  const _component_ChargingHistory = _resolveComponent("ChargingHistory");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.chargeActual.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    to: "start",
    custom: ""
  }, {
    default: _withCtx(({
      navigate
    }) => [_createVNode(_component_el_button, {
      type: "primary",
      icon: "Plus",
      size: "large",
      onClick: navigate
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("charging.start")), 1)]),
      _: 2
    }, 1032, ["onClick"])]),
    _: 1
  })])) : _createCommentVNode("", true), _createVNode(_component_ChargingHistory, {
    chargingProcesses: _ctx.chargeHistory,
    onRefresh: _ctx.load
  }, null, 8, ["chargingProcesses", "onRefresh"])], 64);
}